<template>
	<div class="needBox">
		<div class="topBox" style="background: #fff;padding-top: 10px;padding-bottom: 10px;">
			<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
			<el-input v-model="searchKey" placeholder="需求名称" style="width: 250px;;margin-right: 10px;" clearable></el-input>
			<!-- <label class="label">需求状态: </label>
			<el-select v-model="needState" style="width: 150px;margin-right: 10px;"  clearable>
				<el-option label="全部" :value="null"></el-option>
				<el-option v-for="item in needStateList" :key="item.id" :label="item.value" :value="item.id">
				</el-option>
			</el-select> -->
			<el-button type="primary" @click="goodsFilter">查询</el-button>
			<el-button @click="AddNeed">添加</el-button>
		</div>

		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px;background: #fff;padding-bottom: 10px;">
			<el-table :data="broadData" style="width: 100%;" v-loading="loading" ref="multipleTable">
				<el-table-column prop="Title" label="需求名称" width="180">
					<template slot-scope="scope">
						<div class="specialTitle">{{scope.row.Title}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="StateValue" label="需求状态">
				</el-table-column>
				<el-table-column prop="AddTime" label="提交时间">
				</el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<el-button type="text" class="pointers" @click="gettransData(scope.row)">查看详情</el-button>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>


	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		malldemandList
	} from '@/api/TurnTomySelf'
	export default {
		data() {
			return {
				searchKey: '',
				broadData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				needState: null,
				needStateList: [{
					value: '待实现',
					id: 0
				}, {
					value: '开发中',
					id: 1
				}, {
					value: '已实现',
					id: 2
				}, {
					value: '规划中',
					id: 3
				}, {
					value: '已排期',
					id: 4
				}]
			}
		},
		created() {
			this.getList();
		},
		methods: {
			//添加详情
			AddNeed() {
				this.$router.push({
					path: "/addmyNeed"
				})
			},
			//查看详情
			gettransData(record) {
				this.$router.push({
					path: "/needDetail",
					query: {
						Id: record.Id
					}
				})
			},
			// 获取列表
			async getList() {
				try {
					this.loading = true

					let data = {
						KeyWords: this.searchKey, // 搜索关键字
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
					}
					let result = await malldemandList(data);
					this.Total = result.Result.Total;
					this.broadData = result.Result.Results || [];

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			goodsFilter() {
				this.currentPage = 1
				this.getList()
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less">
	.specialTitle {
		width: 100%;
		text-overflow: -o-ellipsis-lastline;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		line-clamp: 2;
		-webkit-box-orient: vertical;
	}
</style>
